import Img from 'gatsby-image';
import * as React from 'react';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';

import { Tiny } from '../text';
import * as StyledComponents from './HandshakeWhitepaper.StyledComponents';

const makeId = string => {
  return string
    .toLowerCase()
    .trim()
    .replace(/ /g, '-');
};

const baseRenderer = {
  renderNode: {
    [BLOCKS.HEADING_2]: (_, children) => {
      return (
        <StyledComponents.H2 id={makeId(children[0])} weight="medium">
          {children}
        </StyledComponents.H2>
      );
    },
    [BLOCKS.HR]: () => {
      return <StyledComponents.Divider />;
    },
    [BLOCKS.PARAGRAPH]: (_, children) => {
      return <StyledComponents.ParagraphText>{children}</StyledComponents.ParagraphText>;
    },
    [INLINES.HYPERLINK]: ({ data }, children) => {
      const href = data.uri;
      const internal = href.startsWith('#');
      return (
        <StyledComponents.Anchor
          href={href}
          rel={internal ? null : 'noopener noreferrer'}
          target={internal ? null : '_blank'}>
          {children}
        </StyledComponents.Anchor>
      );
    },
    [BLOCKS.OL_LIST]: (_, children) => {
      return <StyledComponents.OrderedList as="ol">{children}</StyledComponents.OrderedList>;
    },
  },
};

const whitepaperRenderer = figures => {
  return {
    renderNode: {
      ...baseRenderer.renderNode,
      [BLOCKS.HEADING_1]: (_, children) => {
        return <StyledComponents.H1 weight="medium">{children}</StyledComponents.H1>;
      },
      [BLOCKS.HEADING_3]: (_, children) => {
        return (
          <StyledComponents.H3 id={makeId(children[0])} weight="medium">
            {children}
          </StyledComponents.H3>
        );
      },
      [BLOCKS.HEADING_4]: (_, children) => {
        return (
          <StyledComponents.H4 id={makeId(children[0])} weight="medium">
            {children}
          </StyledComponents.H4>
        );
      },
      [BLOCKS.HEADING_5]: (_, children) => {
        return (
          <StyledComponents.ParagraphText id={makeId(children[0])} weight="medium">
            {children}
          </StyledComponents.ParagraphText>
        );
      },
      [BLOCKS.EMBEDDED_ASSET]: (node, _) => {
        const id = node.data.target.sys.id;
        const imageData = figures.get(id);
        return (
          <StyledComponents.Figure>
            <Img {...imageData} />
            <Tiny as="figcaption" weight="medium" styled="italic">
              {imageData.description}
            </Tiny>
          </StyledComponents.Figure>
        );
      },
    },
    renderMark: {
      [MARKS.CODE]: text => {
        return <StyledComponents.Code>{text}</StyledComponents.Code>;
      },
    },
  };
};

const footnotesRenderer = {
  renderNode: {
    ...baseRenderer.renderNode,
  },
};

const referencesRenderer = {
  renderNode: {
    ...baseRenderer.renderNode,
    [BLOCKS.PARAGRAPH]: (_, children) => {
      return (
        <StyledComponents.TrimmedParagraphText>{children}</StyledComponents.TrimmedParagraphText>
      );
    },
    [BLOCKS.UL_LIST]: (_, children) => {
      return <StyledComponents.UnorderedList as="ul">{children}</StyledComponents.UnorderedList>;
    },
  },
};

const renderers = {
  whitepaper: whitepaperRenderer,
  footnotes: footnotesRenderer,
  references: referencesRenderer,
};

export { renderers };
