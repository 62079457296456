import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import SEO from '../components/seo';
import Layout from '../components/layout';
import { renderers } from '../components/HandshakeWhitepaper/HandshakeWhitepaper.Renderers';
import { ArticleRoot } from '../components/HandshakeWhitepaper/HandshakeWhitepaper.StyledComponents';
import { usePageMetaAttributes } from '../utils/usePageMetaAttributes';

const whitepaperQuery = graphql`
  query WhitepaperQuery {
    content: contentfulHandshakeWhitepaper {
      ...HandshakeWhitepaperContent
    }
    images: contentfulHandshakeWhitepaper {
      ...HandshakeWhitepaperImages
    }
  }
`;

const HandshakeWhitepaperPage = () => {
  const meta = usePageMetaAttributes('handshakeWhitepaper');
  const { content, images } = useStaticQuery(whitepaperQuery);

  React.useLayoutEffect(() => {
    if (typeof window !== 'undefined' && document.location.hash) {
      document.querySelector(document.location.hash).scrollIntoView({ block: 'start' });
    }
  }, []);

  const figures = React.useMemo(() => {
    const map = new Map();
    for (const image of Object.values(images)) {
      const { contentful_id, ...data } = image;
      map.set(image.contentful_id, data);
    }
    return map;
  }, [images]);

  return (
    <Layout>
      <SEO {...meta} />
      <ArticleRoot>
        {documentToReactComponents(content.whitepaper.json, renderers.whitepaper(figures))}
        {documentToReactComponents(content.footnotes.json, renderers.footnotes)}
        {documentToReactComponents(content.references.json, renderers.references)}
      </ArticleRoot>
    </Layout>
  );
};

export default HandshakeWhitepaperPage;
