import styled from 'styled-components';
import { DisplayLarge, DisplayTiny, HeaderSmall, Huge, Large } from '../text';

export const ArticleRoot = styled.article`
  width: 880px;
  margin: 0 auto;
  padding-bottom: ${({ theme }) => theme.spacing(25)};

  @media ${({ theme }) => theme.breakpoints.md} {
    width: 100%;
    padding-left: 16%;
    padding-right: 16%;
  }
  @media ${({ theme }) => theme.breakpoints.sm} {
    width: 100%;
    padding-left: ${({ theme }) => theme.spacing(3)};
    padding-right: ${({ theme }) => theme.spacing(3)};
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.color.grey30};
  margin-top: ${({ theme }) => theme.spacing(1.5)};
`;

export const Anchor = styled.a`
  color: ${({ theme }) => theme.color.nbPrimary};
  transition: ${({ theme }) => theme.transitions.color};

  &:hover {
    color: ${({ theme }) => theme.color.nbPrimaryDark};
  }
`;

export const H1 = styled(DisplayLarge)`
  margin-top: ${({ theme }) => theme.spacing(10)};
`;

export const H2 = styled(DisplayTiny).attrs(props => ({ as: 'h2' }))`
  margin-top: ${({ theme }) => theme.spacing(8)};
`;

export const H3 = styled(HeaderSmall).attrs(props => ({ as: 'h3' }))`
  margin-top: ${({ theme }) => theme.spacing(4)};
`;

export const H4 = styled(Huge).attrs(props => ({ as: 'h4' }))`
  margin-top: ${({ theme }) => theme.spacing(5)};
`;

export const ParagraphText = styled(Large)`
  text-align: justify;
  margin-top: ${({ theme }) => theme.spacing(2.5)};
`;

export const TrimmedParagraphText = styled(ParagraphText)`
  width: 100%;  
  overflow: hidden;
  text-align: start;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const OrderedList = styled(ParagraphText).attrs(props => ({ as: 'ol' }))`
  list-style: auto;
  text-align: start;
  padding-left: 1em;

  li {
    padding-left: 0.75em;
  }

  * {
    margin-top: 0;
  }
`;

export const UnorderedList = styled(OrderedList).attrs(props => ({ as: 'ul' }))`
  padding: 0;
  list-style: none;

  *,
  li {
    padding: 0;
  }
`;

export const Code = styled.code`
  padding: ${({ theme }) => theme.spacing(0.25)} ${({ theme }) => theme.spacing(0.5)};
  background: ${({ theme }) => theme.color.grey20};
  border-radius: ${({ theme }) => theme.spacing(0.5)};
`;

export const Figure = styled.figure`
  width: 100%;
  margin: 0;
  padding-top: ${({ theme }) => theme.spacing(2)};
  overflow: hidden;

  figcaption {
    margin-top: ${({ theme }) => theme.spacing(1)};
  }
`;
